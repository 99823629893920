import React from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Resources from "../components/resources"
import NotFound from "../components/notFound"
import { linkResolver } from "../utils/linkResolver"

const ResourcesPage = ({ data }) => {
  if (!data.allPrismicResource.nodes.length > 0) {
    return (
      <Layout>
        <Seo title="404: Not found" />
        <NotFound />
      </Layout>
    )
  } else {
    return (
      <Layout>
        <Seo title="Resources" />
        <div className="pt-8 sm:pt-12 md:pt-18 pb-18">
          <Resources allPrismicResource={data.allPrismicResource} />
        </div>
      </Layout>
    )
  }
}

export default withPrismicPreview(ResourcesPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
])

export const query = graphql`
  query ResourcesPageQuery {
    allPrismicResource(sort: { fields: data___date, order: DESC }) {
      nodes {
        uid
        _previewable
        data {
          heading
          excerpt_heading
          excerpt {
            html
          }
          link {
            url
            target
          }
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
