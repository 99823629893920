import * as React from "react"
import { Link } from "gatsby"

const NotFound = () => {
  return (
    <div className="flex flex-col items-center text-center px-ogs py-8 sm:py-12 md:py-18 space-y-4">
      <h1 className="heading-alpha">404: Not Found</h1>
      <p className="text-alpha">
        Sorry, but the page you were looking for could not be found.
      </p>
      <Link to="/" className="btn-primary btn-primary--back">
        Home
      </Link>
    </div>
  )
}

export default NotFound
